<template>
  <m-empty-state icon="error_outline" label="404 Page not found." />
</template>

<script>
import { MEmptyState } from 'components/'
export default {
  components: { MEmptyState }
}
</script>
